import { parseISO } from 'date-fns';
import { camelCaseToText } from './basic';
import { Schema, PageConfig } from '@app/pages';
import JSZip from 'jszip';
import { exportPdf } from './pdfExporter';
import { pageFormBanType } from '@app/pages';
import { pageFilterBanList } from '@app/pages';
import { useAppSchema } from '../hooks/useAppSchema';
import * as XLSX from 'xlsx';

export const getCleanHeader = (headers: Schema[]) => {
    const cleanHeader: any = [];
    headers.forEach((header: Schema) => {
        if (!pageFormBanType.includes(header.type)) {
            cleanHeader.push(
                header.title ? header.title : camelCaseToText(header.ref)
            );
        }
    });
    return cleanHeader;
};
const { getData } = useAppSchema();

export const getCleanDatas = (
    datas: any,
    headers: Schema[],
    pdf: boolean = false,
    getNumber = false
): any[][] => {
    const groupedData: any[] = datas.tableData;
    if (datas.aggregate) {
        let dataToPush = datas.aggregate._sum;
        dataToPush[headers[0].ref] = 'TOTAL';
        groupedData.push(datas.aggregate._sum);
    }
    const datasArray: any[][] = [];
    groupedData.forEach((data) => {
        const dataValue: any = [];
        headers.forEach((header) => {
            if (!pageFilterBanList.includes(header.type)) {
                const cleanField = getCleanField(
                    getData(data, header.ref),
                    header.type,
                    getNumber
                );
                dataValue.push(
                    getTypeField(header.type || '', cleanField, pdf)
                );
            }
        });
        datasArray.push(dataValue);
    });
    return datasArray;
};

const getCleanField = (
    field: any,
    type: string | undefined,
    getNumber = false
) => {
    if (type === 'amount' && getNumber) {
        return parseFloat(field);
    }
    let removeChart = '';
    if (typeof field === 'string') {
        removeChart = field.replaceAll('#', '');
    } else if (field === null || field === undefined) {
        removeChart = '';
    } else {
        removeChart = field;
    }
    return removeChart;
};

const getTypeField = (type: string, field: any, pdf: boolean = false) => {
    let cleanField;
    if (field === '') return '';
    if (type === 'date-full') {
        cleanField = parseISO(field).toLocaleString().replaceAll('/', '.');
    } else if (type === 'date') {
        cleanField = parseISO(field)
            .toLocaleString()
            .replace(',', '')
            .split(' ')[0]
            .replaceAll('/', '.');
    } else if (type === 'relation') {
        cleanField = field.length + ' relations';
    } else if (type === 'amount' || type === 'money') {
        cleanField = field;
    } else if (type === 'float') {
        cleanField = field.includes('.') ? field : `${field}.00`;
    } else if (type === 'bool') {
        cleanField = field ? 'yes' : 'no';
    } else {
        cleanField = !pdf ? '"' + field + '"' : field.toString();
    }
    return cleanField;
};

export function exportCSVFile(headers: any[], datas: any, fileName: string) {
    const cleanHeader = getCleanHeader(headers);
    const cleanDatas = getCleanDatas(datas, headers);
    const rows = cleanDatas;
    rows.unshift(cleanHeader);
    const file = rows.map((row) => row.join(',')).join('\n');
    downloadFile(file, fileName, 'csv');
}

export function exportJsonFile(headers: any[], datas: any, fileName: string) {
    const dataStr = JSON.stringify(datas);
    downloadFile(dataStr, fileName, 'json', true);
}

export function exportXMLFile(headers: any[], datas: any, fileName: string) {
    let XmlDatas = OBJtoXML(datas.tableData);
    if (datas.aggregate) XmlDatas += OBJtoXML(datas.aggregate);
    const XmlDatasFormated = prettifyXml('<data>' + XmlDatas + '</data>');
    downloadFile(XmlDatasFormated, fileName, 'xml', true);
}

export function exportPDFFile(headers: any[], datas: any[], fileName: string) {
    const cleanHeader = getCleanHeader(headers);
    const cleanDatas = getCleanDatas(datas, headers, true);

    exportPdf(cleanHeader, cleanDatas, fileName);
}
export const exportZipFile = async (
    headers: any[],
    datas: any,
    fileName: string
) => {
    var zip: any = new JSZip();
    datas.tableData.forEach((data: any, index: number) => {
        const XmlDatas = OBJtoXML(data);
        const XmlDatasFormated = prettifyXml('<data>' + XmlDatas + '</data>');
        let customFileName;
        if (fileName === 'Factures') {
            customFileName =
                fileName.substring(0, fileName.length - 1) +
                ' - ' +
                data.numeroFacture;
        } else {
            customFileName =
                fileName.substring(0, fileName.length - 1) + ' - ' + index;
        }
        zip.file(`${customFileName}.xml`, XmlDatasFormated);
    });
    const content = await zip.generateAsync({ type: 'blob' });
    downloadFile(content, fileName, 'zip', true);
};

export const exportExcelFile = (
    headers: any[],
    datas: any,
    fileName: string
) => {
    const cleanHeader = getCleanHeader(headers);
    const cleanDatas = getCleanDatas(datas, headers, true);
    const rows = cleanDatas.map((row) =>
        row.map((cell) =>
            !isNaN(parseFloat(cell)) && isFinite(cell) ? parseFloat(cell) : cell
        )
    );
    rows.unshift(cleanHeader);
    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wb = XLSX.utils.book_new();

    ws['!cols'] = [];
    headers.forEach(() => {
        ws['!cols']!.push({ width: 30 });
    });

    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export function downloadFile(
    file: any,
    name: string,
    type: string,
    blob = false
) {
    if (blob) {
        var url = URL.createObjectURL(new Blob([file], { type: 'text/plain' }));
    } else {
        let content = 'data:text/' + type + ';charset=utf-8,' + file;
        var url = encodeURI(content);
    }
    var link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);
    document.body.appendChild(link);
    link.click();
}

function OBJtoXML(obj: any) {
    var xml = '';
    for (var prop in obj) {
        xml += '<' + prop + '>';
        if (Array.isArray(obj[prop])) {
            for (var array of obj[prop]) {
                // A real botch fix here
                xml += '</' + prop + '>';
                xml += '<' + prop + '>';

                xml += OBJtoXML(new Object(array));
            }
        } else if (typeof obj[prop] == 'object') {
            xml += OBJtoXML(new Object(obj[prop]));
        } else {
            xml += obj[prop];
        }
        xml += '</' + prop + '>';
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml;
}

var prettifyXml = function (sourceXml: any) {
    var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    var xsltDoc = new DOMParser().parseFromString(
        [
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'),
        'application/xml'
    );

    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    var resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};
