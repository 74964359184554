import { PageConfig } from '../type';

export const listeNoireViewSchema: PageConfig = {
    config: {
        indentifier: 'listeNoireViewSchema',
        modelName: 'listeNoires',
        itemName: 'une listeNoire',
        apiUrl: '/view-parrainages',
        options: ['filter', 'order', 'updateCustom', 'updateManyCustom'],
        banExport: ['pdf'],
        customFilter: { filterAsigneListNoire: true },
    },

    schemas: [
        {
            style: { width: 140 },
            ref: 'collaborateur/ref',
            title: 'Id Collaborateur',
            ban: ['update'],
        },
        {
            style: { width: 200 },
            ref: 'entreprise',
            type: 'pargraphe',
            search: true,
            ban: ['update'],
        },
        {
            style: { width: 240 },
            ref: 'libelleFacture',
            search: true,
            ban: ['update'],
        },
        {
            style: { width: 200 },
            ref: 'coordonneesCorrespondance',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'telephone',
            ban: ['order', 'filter', 'update'],
        },
        {
            style: { width: 140 },
            ref: 'telephone2',
            ban: ['order', 'filter', 'update'],
        },
        {
            style: { width: 140 },
            ref: 'accordDe',
            ban: ['order', 'filter', 'update'],
        },
        {
            style: { width: 200 },
            ref: 'createdAt',
            title: 'Date Facture',
            type: 'date',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'dateAccord',
            type: 'date',
            ban: ['update'],
        },
        {
            style: { width: 280 },
            ref: 'email',
            ban: ['order', 'filter', 'update'],
        },
        {
            style: { width: 240 },
            ref: 'noteInterne',
            type: 'HTML',
            ban: ['update'],
        },
        { style: { width: 140 }, ref: 'publicite', ban: ['update'] },
        {
            style: { width: 140 },
            ref: 'reconduction',
            type: 'bool',
            ban: ['update'],
        },

        {
            style: { width: 140 },
            ref: 'mandat/ref',
            title: 'idClub',
            ban: ['update'],
        },

        {
            style: { width: 140 },
            ref: 'mandat/club/nom',
            title: 'Club',
            ban: ['update'],
        },

        {
            style: { width: 140, fixed: true },
            ref: 'facture/numeroFacture',
            title: 'Numero Facture',
            search: true,
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'montantPromesse',
            type: 'money',
            title: 'Montant promesse',
            search: true,
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'soldePaye',
            type: 'money',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'datesRappels',
            type: 'date',
            ban: ['update'],
        },
        {
            style: { width: 340 },
            ref: 'noteLn',
            title: 'Commentaires LN',
            type: 'pargraphe2',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'annulation',
            type: 'bool',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'facture/paiement',
            type: 'bool',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'listeNoire/dateListeNoire',
            title: 'Date de liste noire',
            type: 'date',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'ListeNoireAffecteeA',
            title: 'Affecté à',
            form: { custom: 'listeNoireAffecteeA' },
        },
    ],
};

export default listeNoireViewSchema;
